.about_container {
  margin: 0 auto;
  width: 90%;
}
.emphasized {
  margin-top: 4rem;
}

.emphasized .emphasized_buttons .btn,
.btn-secondary {
  font-size: 1.1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
}
.emphasized_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.emphasized .emphasized_buttons a {
  color: white;
  /* width: 200px; */
  display: inline-block;
}

.team {
  display: flex;
}

.team-member {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 240px;
  height: 350px;
  text-align: left;
}

.emphasized {
  text-align: center;
  font-weight: 700;
  font-size: 64px;
}
.tag {
  text-align: center;
  margin-top: 50px;
  color: grey;
  font-size: 25px;
  word-spacing: 5px;
}
.contact {
  display: flex;
  justify-content: space-around;
}
.start_btn {
  color: #fff;
  text-align: center;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 100px;
  padding: 1rem 2rem;
  font-weight: 600;
  text-decoration: none;
}
.start_btn:hover {
  background-color: #a47f6f;
}
@media (max-width: 900px) {
  .emphasized {
    text-align: center;
    font-weight: 700;
    font-size: 50px;
  }
  .contact {
    margin-bottom: 3rem;
  }
}
@media (max-width: 600px) {
  .emphasized {
    text-align: center;
    font-weight: 700;
    font-size: 40px;
  }
  .team-member {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: white;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 171px;
    height: 250px;
    text-align: left;
  }
  .team {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
  }
  .contact {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
}
