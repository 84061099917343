.share-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.share-container {
  background-color: white;
  padding: 2rem 3rem;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 95%;
  max-height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
}

.share-container h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.share-container button {
  color: #fff;
  text-align: center;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 10px;
  padding: 0.7rem 1rem;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  align-self: center;
}

.share-container button:hover {
  background-color: #a47f6f;
}

.share-content {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 2rem;
  grid-column-gap: 2rem; */
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.share-close {
  display: flex;
  justify-content: center;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.share-image {
  width: 200px;
  height: 208px;
  overflow: hidden;
}

.share-image img {
  width: 394px;
  height: 344px;
  margin: -113px 0px 0px -85px;
}

.preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table_detail th,
.table_detail td {
  padding: 0px;
  font-size: 0.8rem;
}

.filled-table {
  /* width: 150px; */
}
