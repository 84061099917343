.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.popup-content {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

  max-width: 95%;
  max-height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
.popup-image img {
  width: 600px;
  height: 400px;
  border: 1px solid black;
  border-radius: 5px;
}
.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.popup-text p {
  margin-bottom: 20px;
}

.popup-text h1 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: left;
}

.popup-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.popup-text {
  flex: 1;
  margin-right: 40px;
  width: 500px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1000px) {
  .popup-content {
    flex-direction: column;
  }
  .popup-image img {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .popup-image img {
    width: 100%;
    height: 300px;
  }
}
