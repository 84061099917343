.profile-content {
  display: inline-block;
  width: 35%;
  margin: 8rem 30%;
  border: 3px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.profile-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0rem;
}
.profile-image {
  height: 300px;
  width: 300px;
  margin-bottom: 2rem;
  border: 5px solid #e0e0e0;
  border-radius: 50%;
}

.profile-image img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
}

.profile-details div {
  margin-bottom: 1rem;
}
