.blog-main {
  max-width: 704px;
  margin: 0 auto;
  padding-top: 8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.blog-title {
  font-size: 42px;
  letter-spacing: -0.011em;
  line-height: 52px;
  font-weight: 400;
}

.blog-image {
  width: 650px;
  height: 394px;
  margin: 2rem 0rem;
}

.blog-image img {
  width: 100%;
  height: 100%;
}

.blog-content {
  line-height: 32px;
  letter-spacing: -0.003em;
  font-size: 20px;
}

@media (max-width: 1000px) {
  .blog-main {
    padding-top: 1rem;
  }
}

@media (max-width: 750px) {
  .blog-title {
    letter-spacing: -0.014em;
    line-height: 38px;
    font-size: 32px;
  }
  .blog-image {
    height: auto;
    width: 100%;
  }
}
