table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}
.user-head {
  background-color: #747171;
  color: white;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.select,
.label {
  color: #414141;
  display: block;
  font-size: 1rem;
  border: none;
}

.select-box1 {
  background: #ececec;
}
