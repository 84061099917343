.input-admin-row {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  gap: 5px;
}

.input p {
  color: red;
  font-size: 0.7rem;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}

.pagination button {
  border: none;
  border-radius: 50%;
  padding: 8px 14px;
  cursor: pointer;
  font-size: 1rem;
}
.pagination i {
  cursor: pointer;
}
