.carusel-content {
  margin: 0 auto;
  max-width: 50%;
  margin-top: 2rem;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(1.2);
}

.mySwiper2 {
  height: 5%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper2 .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper2 .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper2 img {
  border: 1px solid var(--primary);
  object-fit: cover;
  transform: scale(1);
}
.swiper-zoom-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  display: block;
}

@media (max-width: 750px) {
  .carusel-content {
    max-width: 95%;
  }
}
