.header {
  position: fixed;
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  padding: 20px;
  border-bottom: 2px solid #e6e8edcc; /* Example border */
  margin-left: 5rem;
  z-index: 2;
  background-color: #fff;
}
.breadcrumb {
  display: flex;
  list-style: none;
  padding: 8px 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 8px;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #000;
}

.breadcrumb-item.active {
  color: #6c757d;
}
.user-info {
  display: flex;
  align-items: center;
}
.user-others {
  background: inherit;
  border: none;
}

.user-info .user-image {
  border-radius: 50%;
  width: 33px;
  height: 33px;
}
.user-info img {
  margin-right: 1.3rem;
}

.reload-button {
  background: inherit;
  border: none;
  cursor: pointer;
}
.show {
  position: absolute;
  left: -130px;
  top: 40px;
  padding: 1rem;
  background: white;
  border-radius: 4px;
  box-shadow: 0 8px 64px #c6c5c480;
  width: 10rem;
}
.show div {
  margin-bottom: 0.7rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: max-content;
}
.show i {
  margin-right: 0.3rem;
}
@media (max-width: 700px) {
  .header {
    margin-left: 0rem;
    width: 100%;
  }
}
