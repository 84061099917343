* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --blue: #37367f;
  --black: #1c2434;
  --primary: #cc9c87;
  --mainText: #101010;
  --green: "#CFFBBF";
  --lightBlack: "#4D4D4D";
}
html {
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  font-family: "Plus Jakarta Sans", sans-serif;
  overflow-x: hidden;
  /* font-weight: "400"; */
}
.App {
  overflow-x: hidden;
}
