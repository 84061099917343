.popup-input-content {
  background-color: white;
  padding: 1.5rem 2rem;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 95%;
  max-height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  width: 40%;
}
.popup-input {
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.popup-input input {
  width: 100%;
}
.popup-input-text h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}
.popup-input-text {
  width: 100%;
}
.popup-buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.unit-button {
  background: none;
  border: 3px solid grey;
  border-radius: 5px;
  padding: 0.3rem 0.6rem;
  font-size: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
.unit-head {
  margin-bottom: 0.5rem;
}

.contianer-inputs div {
  margin-top: 0.7rem;
}
.contianer-inputs input {
  margin-top: 0.2rem;
}
.contianer-inputs p {
  color: red;
  font-size: 0.7rem;
}
