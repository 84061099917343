.container {
  display: flex;
  width: 100%;
  height: 100vh;
  max-width: 1600px;
  background-color: #fff;
}

.left-side,
.right-side {
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-side {
  padding: 30px;
  width: 40%;
  background-color: #fff;
  padding-top: 8%;
}

.right-side {
  padding-left: auto;
  padding-right: auto;
  padding-top: auto;
  padding-bottom: 0;
  width: 60%;
  background-color: #e6e8ed;
  padding-right: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.logo-footer img {
  width: 40px;
  margin-right: 10px;
  vertical-align: middle;
}
.logo img {
  width: 40px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.logo span {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  vertical-align: middle;
}

h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin: 20px 0 10px;
}

.subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
  margin-top: 2rem;
}

.input-group input {
  width: 80%;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.input-group .toggle-password {
  position: absolute;
  top: 35%;
  right: 8%;
  background: none;
  border: none;
  cursor: pointer;
}

.input-group .toggle-password img {
  width: 18px;
  height: 13px;
  color: #b1b5bc;
}

.options {
  width: 85%;
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 40px;
}

.remember-me {
  display: flex;
  align-items: center;
}

.remember-me input {
  margin-right: 5px;
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

.image-container {
  position: relative;
  width: 20px; /* Adjust the width to match the image size */
  height: 20px; /* Adjust the height to match the image size */
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.login-button {
  /* width: 25%; */
  padding: 12px;
  background-color: #000;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 0.5rem;
}

.login-button:hover {
  background-color: #333;
}

.testimonial {
  position: relative;
  text-align: left;
  max-width: 80%;
  top: 0;
}

.testimonial p {
  font-weight: 600;
  line-height: 38px;
  font-size: 24px;
  color: #1b212f;
  margin-bottom: 20px;
}

.author {
  display: flex;
  align-items: center;
  padding-left: 32%;
}

.author img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.author-info h4 {
  margin: 0;
  font-size: 16px;
}

.author-info p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.dashboard-image {
  text-align: center;
  /* margin-top: 30px; */
}
.dashboard-image img {
  width: 100%;
  max-width: 80%;
  border-radius: 5px;
  border-width: 3px; /* Adjust the thickness as needed */
  border-style: solid; /* Add this line to specify the border style */
  border-color: #000;
}

footer {
  text-align: center;
  padding: 10px 0;
  background-color: #fff;
  width: 100%;
  margin-top: auto;
}

footer p {
  color: #666;
  font-size: 14px;
}

footer a {
  color: #666;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

/* Add this to your login.css */

.button-group {
  display: flex;
  flex-direction: column; /* Align buttons vertically */
  align-items: center; /* Center align buttons horizontally */
  gap: 50px; /* Space between buttons */
  margin-top: 20px;
}

.action-button {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  border: none;
  padding: 15px 40px; /* Increased padding for larger buttons */
  text-align: center;
  text-decoration: none;
  font-size: 18px; /* Increased font size */
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%; /* Make the buttons wider */
  max-width: 75%; /* Maximum width for buttons */
  border-radius: 5px; /* Optional: add rounded corners */
}

.action-button:hover {
  background-color: #333; /* Dark grey on hover */
}
.error {
  color: red;
  font-size: 0.8rem;
  position: absolute;
  left: 11%;
  top: 105%;
  margin-bottom: 1rem;
}
.all-errors {
  color: red;
  font-size: 0.8rem;
  position: absolute;
  top: 24%;
  left: 11%;
}
.all-errors2 {
  color: red;
  font-size: 0.8rem;
  position: absolute;
  top: 40%;
  left: 11%;
}
.blue-text {
  color: skyblue;
  text-decoration: none;
  margin-top: 0.7rem;
}
.blue-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.resend-button {
  display: block;
  background: none;
  border: none;
  color: skyblue;
  margin: 0 auto;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}
.forgot-password {
  position: absolute;
  left: 70%;
  font-size: 0.8rem;
  color: skyblue;
  cursor: pointer;
  user-select: none;
}
@media (max-width: 1050px) {
  h1 {
    font-size: 26px;
  }
  .forgot-password {
    left: 58%;
  }
}
@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
  .left-side {
    width: 100%;
    background-color: #fff;
    padding: 0;
    padding-top: 8%;
  }
  .right-side {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 600px) {
  .testimonial p {
    font-weight: 600;
    line-height: 25px;
    font-size: 17px;
    color: #1b212f;
    margin-bottom: 20px;
  }
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  .action-button {
    width: 35%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
  .dashboard-image img {
    width: 100%;
    max-width: 95%;
    height: 194px;
    margin-bottom: 2rem;
  }
  h1 {
    font-size: 24px;
    line-height: 36px;
  }
  .forgot-password {
    left: 64%;
  }
}
