.order-details {
  padding-left: 7rem;
  padding-top: 7rem;
  padding-right: 7rem;
  padding-bottom: 7rem;
}
.info-icon {
  position: relative;
  display: inline-block;
}

.info-icon img {
  width: 20px;
  height: 20px;
  margin-top: 22px;
  margin-left: 5px;
  cursor: pointer;
}

.tooltip {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  left: 125%; /* Position to the right of the icon */
  top: 50%;
  transform: translateY(-50%);
  width: 600px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -5px; /* Position arrow on the left side of the tooltip */
  transform: translateY(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}
.info-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.head {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.table-info {
  margin: 20px 0;
  /* border-radius: 5px; */
  border-collapse: collapse;
  /* width: 80%; */
  /* font-family: "Manrope", sans-serif; */
}

.table-info table {
  width: 100%;
  border-collapse: collapse;
}

.table-info th,
.table-info td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.table-info th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: 700;
}

.table-info tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-info tr:hover {
  background-color: #f1f1f1;
}

.table-info caption {
  margin: 10px 0;
  font-size: 1.5em;
  font-weight: 500;
  text-align: left;
}

.table-info td a {
  color: #007bff;
  text-decoration: none;
}

.table-info td a:hover {
  text-decoration: underline;
}
.filled_table {
  width: 150px;
}
.filled_table th {
  margin: 0;
  padding-left: 0.5rem;
  width: 100%;
  padding: 12px;
  border: none;
}
.filled_table tr {
  border: 1px solid #ddd;
  /* padding: 12px; */
  text-align: left;
  display: flex;
}
.filled_table tr:nth-child(even) {
  background-color: white;
}
.filled_table tr:nth-child(odd) {
  background-color: #f9f9f9;
}
.table {
  overflow-x: auto;
}

.cont_btn {
  font-size: 1rem;
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 1rem;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-color: rgba(0, 0, 0, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 0.1s cubic-bezier(0.19, 1, 0.22, 1);
}

.cont_btn:hover {
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(16, 16, 16, 0.5), 0 0 20px rgba(5, 5, 5, 0.2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}

.container-info {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 16px;
  font-weight: 600;
  margin: 40px 0;
}
.container-info p {
  margin: 1rem 0rem;
}
.block-elements {
  display: block;
}
#fullscreen-button {
  font-size: 10px;
  padding: 5px;
  margin-bottom: 5px;
}
.features {
  padding: 2px;
  text-align: left;
}
.features-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.features-input:focus {
  border-color: #333;
  outline: none;
}
.sku-details {
  flex: 1;
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px; /* Adjusted space between SKU items */
  margin-bottom: 20px; /* Add space below SKU details */
}

.features {
  margin-bottom: 20px;
}
.features h3 {
  margin-bottom: 0.3rem;
}

.btn-cancel,
.btn-apply {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.btn-cancel {
  background-color: #ddd;
  color: #555;
  /* width: 20%; */
}

.btn-cancel:hover {
  background-color: #767676;
  color: black;
}

.btn-apply {
  background-color: #000;
  color: #fff;
}

.btn-apply:hover {
  background-color: #797979;
  color: black;
}

.sku {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #e3e7f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 14%;
}

.sku-label {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.sku-count {
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}

.container-info {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 16px;
  font-weight: 600;
  margin: 40px 0;
  /* border: 1px solid black; */
  /* wid */
}

.container-view {
  margin-bottom: 20px;
  padding: 20px;
}

.edit-loading-pattern {
  margin-bottom: 20px;
}

.edit-pattern-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}
.premium-icon {
  height: 20px;
  width: 20px;
  margin-left: 7px;
  margin-top: 4px;
}
.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
#fullscreen-button {
  font-size: 10px;
  padding: 5px;
  margin-bottom: 5px;
  position: absolute;
  right: 17px;
  bottom: 11px;
  background: white;
  font-size: 1.3rem;
}
.block-elements {
  flex: 1;
  margin-left: 50px;
}
.iframe {
  width: 800px;
  height: 600px;
}
.contenter-view {
  display: flex;
  flex-direction: column;
  position: relative;
}
.full_view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.note {
  width: 60%;
  border-radius: 1rem;
  border: 5px solid var(--primary);
  padding: 1.3rem 1rem;
  margin-top: 1rem;
}
@media (max-width: 1300px) {
  .order-details {
    padding-left: 7rem;
    padding-top: 6rem;
    padding-right: 0rem;
  }
}
@media (max-width: 1200px) {
  .content-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .block-elements {
    margin-left: 0px;
    align-self: baseline;
  }
}
@media (max-width: 950px) {
  .iframe {
    width: 540px;
    height: 524px;
  }
}
@media (max-width: 700px) {
  .order-details {
    padding-left: 2rem;
  }
}
@media screen and (max-width: 600px) {
  /* table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    border: none;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin-bottom: 20px;
    border: 1px solid #ddd;
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    position: absolute;
    left: 6px;
    content: attr(data-label);
    font-weight: bold;
  }
  .table-info th,
  .table-info td {
    border: 1px solid #ddd;
    padding: 7px;
    text-align: left;
  } */
  .iframe {
    width: 400px;
    height: 470px;
  }
  h1 {
    font-size: 24px;
    /* line-height: 0px; */
  }
  .info-icon img {
    margin-top: 12px;
  }
  .container-info {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 600;
    margin: 30px 0;
  }
  .features {
    /* margin-bottom: 8px; */
  }
  .features h3 {
    margin-bottom: 0.5rem;
  }
  .btn-apply {
    padding: 8px 12px;
  }
  .btn,
  .btn-secondary {
    padding: 8px 12px;
  }
}
@media (max-width: 450px) {
  .order-details {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
@media (max-width: 400px) {
  .iframe {
    width: 350px;
    height: 400px;
  }
}
