.contact-main {
  padding: 0rem 2rem;
  padding-top: 9rem;
  padding-bottom: 9rem;
  background-image: linear-gradient(
    90deg,
    hsl(18deg 40% 66%) 0%,
    hsl(18deg 41% 69%) 9%,
    hsl(18deg 42% 72%) 17%,
    hsl(18deg 42% 75%) 26%,
    hsl(18deg 43% 78%) 34%,
    hsl(18deg 43% 80%) 42%,
    hsl(18deg 44% 83%) 51%,
    hsl(18deg 45% 86%) 59%,
    hsl(18deg 45% 89%) 67%,
    hsl(18deg 46% 92%) 75%,
    hsl(17deg 47% 94%) 84%,
    hsl(17deg 47% 97%) 92%,
    hsl(0deg 0% 100%) 100%
  );
  height: 100vh;
}
.form-container {
  background-color: white;
  outline-offset: 0px;
  border-radius: 1rem;
  outline: 4px solid #00000005;
  padding: 2rem 1rem;
  box-shadow: 0 0 8px 4px #00000005;
  /* height: 95%; */
  /* width: 37rem; */
  max-width: 40rem;
}

.contact-section {
  /* display: flex;
  justify-content: space-evenly; */
  height: 100%;
}

.img-cont {
  height: 350px;
  width: 380px;
}

.img-cont img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  outline: 4px solid #00000005;
}

.input-form {
  /* margin-bottom: 1.2rem; */
}
.input-form input {
  margin-top: 7px;
  height: 40px;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: #33475b;
  border: 1px solid #cbd6e2;
  outline: none;
  border-radius: 3px;
}
.input-form input:focus {
  border: 1px solid #cbd6e2;
}
.input-form label {
  font-size: 16px;
  color: var(--lightBlack);
  font-weight: 500;
  line-height: 20px;
}
.error-contact {
  font-size: 12px;
  color: red;
}
@media (max-width: 1000px) {
  .contact-main {
    height: 100%;
    padding-top: 4rem;
  }
  .contact-section {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
  }
  .form-container {
    height: 100%;
  }
}

@media (max-width: 600px) {
  .form-container {
    /* width: 27rem; */
    height: 100%;
  }
  .contact-main {
    padding: 0rem 1rem;
    padding-top: 2rem;
    padding-bottom: 5rem;
  }
}

@media (max-width: 500px) {
  .form-container {
    /* width: 22rem; */
    height: 100%;
  }
  .img-cont {
    height: 350px;
    width: 350px;
  }
}
