.tabbar {
  grid-column-gap: 0.5rem;
  background-color: #00000014;
  border-radius: 1000px;
  padding: 0.75rem;
  display: flex;
  width: 59%;
  margin: 0 auto;
  justify-content: space-around;
  user-select: none;
}
.tab {
  color: #9f9f9f;
  background-color: #0000;
  border-radius: 1000px;
  justify-content: center;
  align-items: center;
  /* min-width: 180px; */
  font-weight: 600;
  display: flex;
  overflow: hidden;
  padding: 9px 30px;
  position: relative;
  /* transition: background-color 0.2s ease-in; */
  cursor: pointer;
}
.tab::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: var(--primary);
  transition: width 0.5s ease-in-out;
  border-radius: 1000px;
  z-index: -1; /* Puts the background color behind the text */
}
.tab:hover {
  background-color: #ebebeb;
  color: black;
}
/* .tab.active {
  background-color: var(--primary);
  color: white;
} */
.tab.active {
  color: white;
}

.tab.active::before {
  width: 100%;
}
.tab.active:hover {
  background-color: initial !important;
  color: white !important;
}
.tab_container {
  outline-offset: 0px;
  border: 1px #6b6464;
  outline: 2px #000;
  overflow: hidden;
  width: 66%;
  margin: 0 auto;
  height: 415px;
  /* height: auto; */
  outline: 4px solid #00000005;
  border-radius: 1rem;
  margin-top: 2rem;
  padding: 2rem 2rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 8px 64px #cc9c8780;
  background-color: #fff;
}
.head {
  display: flex;
  align-items: center;
}
.head img {
  width: 2rem;
  height: 2rem;
  color: var(--primary);
  color: red;
  background-blend-mode: multiply;
}
.head p {
  font-weight: 700;
  font-size: 1rem;
}
.indi_feat {
  width: 20rem;
}
.all_feat {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0.7rem;
  grid-column-gap: 5rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.role_content {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.role_image {
  border-radius: 1rem;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.role_image img {
  object-fit: cover;
  width: 100%;
  height: 90%;
  border-radius: 1rem;
  border: 12px solid #ebebeb;
}
.role_text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 40%;
}
.role {
  margin-bottom: 1rem;
}
.role h5 {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
}

@media (max-width: 1200px) {
  .tab_container {
    height: 450px;
  }
}
@media (max-width: 1000px) {
  .tab_container {
    width: 95%;
  }
  .tabbar {
    width: 95%;
  }
}

@media (max-width: 850px) {
  .all_feat {
    grid-template-columns: 1fr;
  }
  .tab_container {
    height: auto;
  }
  .indi_feat {
    margin-bottom: 1rem;
  }
}
@media (max-width: 600px) {
  .tab {
    padding: 9px 13px;
  }
}
