.hero-content h1 {
  font-size: 3rem;
  line-height: 60px;
  font-weight: 800;
}
.enquire {
  color: black;
  background-color: white;
  margin-bottom: 25px;
  margin-top: 10px;
  width: 25%;
  display: inline;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 7px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.free {
  background-color: black;
  color: white;
  display: inline;
  width: 25%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 7px;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

@media (max-width: 900px) {
  .outer_img_box {
    width: 90%;
    height: 650px;
  }

  .hero img {
    width: 100%;
    height: 650px;
    display: block;
    border-radius: 30px;
    object-fit: cover;
  }

  main {
    /* width: 90%; */
    margin: 0 auto;
  }

  .hero-content h1 {
    font-size: 2.5rem;
    margin: 0 0 10px 0;
  }
}

@media (max-width: 600px) {
  .outer_img_box {
    width: 95%;
    height: 340px;
    margin: 1.5rem auto;
    padding-top: 18rem;
  }
  .hero img {
    width: 100%;
    height: 310px;
  }
  .hero-content h1 {
    font-size: 1.7rem;
    margin: 0 0 10px 0;
    line-height: 35px;
  }

  .hero-buttons {
    display: flex;
  }

  .all-buttons {
    display: flex;
    justify-content: space-evenly;
  }
  .hero-content p {
    font-size: 1rem;
    line-height: 20px;
    font-weight: 400;
  }

  .enquire {
    color: black;
    background-color: white;
    width: max-content;
    display: inline;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 0.8rem 0.5rem;
    margin: 0;
  }

  .free {
    width: max-content;
    display: inline;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 0.8rem 0.5rem;
  }
}
